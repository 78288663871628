"use client"

import { Divider } from "@/components/ui/data-display/Divider"
import { Stack } from "@/components/ui/layout/Stack"
import { mdiDragVertical } from "@mdi/js"
import { Icon } from "@mdi/react"
import { useTheme } from "@mui/material/styles"
import { useHover } from "@uidotdev/usehooks"
import type { ResizableProps } from "re-resizable"

export const horizontalResizeRight: ResizableProps["enable"] = {
  top: false,
  left: false,
  bottom: false,
  right: true,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}

export const horizontalResizeLeft: ResizableProps["enable"] = {
  top: false,
  left: true,
  bottom: false,
  right: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}

export const verticalResizeTop: ResizableProps["enable"] = {
  top: true,
  left: false,
  bottom: false,
  right: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}

export const verticalResizeBottom: ResizableProps["enable"] = {
  top: false,
  left: false,
  bottom: true,
  right: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}

export interface DragHandlerProps {
  orientation?: "vertical" //  | 'horizontal'
}

export default function DragHandler() {
  const theme = useTheme()
  const [ref, hover] = useHover<HTMLDivElement>()

  const active = hover

  return (
    <Stack
      ref={ref}
      className="absolute top-0 -left-1 bottom-0 cursor-ew-resize"
      alignItems="center"
      sx={{ zIndex: theme.zIndex.drawer + 5 }}
    >
      <Divider
        className={`flex-grow h-auto border-1 ${
          active ? "border-primary" : "border-stone-300"
        } transition-all ease-in-out duration-300`}
        orientation="vertical"
      />
      <Stack
        className={`px-0 py-3 rounded-lg ${
          active ? "bg-primary" : "bg-stone-300"
        } transition-all ease-in-out duration-300`}
      >
        <Icon
          className="transition-all ease-in-out duration-300"
          path={mdiDragVertical}
          size={0.75}
          color={
            active
              ? theme.palette.primary.contrastText
              : theme.palette.grey[600]
          }
        />
      </Stack>
      <Divider
        className={`flex-grow h-auto border-1 ${
          active ? "border-primary" : "border-stone-300"
        } transition-all ease-in-out duration-300`}
        orientation="vertical"
      />
    </Stack>
  )
}
