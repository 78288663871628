"use client"

import { getCategoryIcon } from "@/app/[locale]/(document)/_utils/categoryStyles"
import { trpc } from "@/app/_trpc/client"
import { Chip } from "@/components/ui/data-display/Chip"
import { Divider } from "@/components/ui/data-display/Divider"
import { List } from "@/components/ui/data-display/List"
import { ListItemButton } from "@/components/ui/data-display/ListItemButton"
import { ListItemIcon } from "@/components/ui/data-display/ListItemIcon"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { ListSubheader } from "@/components/ui/data-display/ListSubheader"
import { Tooltip } from "@/components/ui/data-display/Tooltip"
import { Typography } from "@/components/ui/data-display/Typography"
import { CircularProgress } from "@/components/ui/feedback/CircularProgress"
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@/components/ui/feedback/Dialog"
import useDialog from "@/components/ui/feedback/Dialog/useDialog"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { TextField } from "@/components/ui/inputs/TextField"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import { Collapse } from "@/components/ui/utils/Collapse"
import { useTranslation } from "@/i18n"
import type { CaseRouter } from "@/server/types/server/case/trpc/routers/caseRouter"
import { DebounceDelay } from "@/utils/constants"
import { useGetUserSessionData } from "@/utils/lib"
import { COMMON_USER_ROUTE, LAWFIRM_ONLY_ROUTE } from "@/utils/middleware"
import { replaceSlugs } from "@/utils/navigation"
import { assertUnreachable } from "@/utils/types/helpers"
import {
  mdiAppleKeyboardCommand,
  mdiArrowLeftBottom,
  mdiCar,
  mdiCheckCircle,
  mdiFolder,
  mdiHumanMaleFemaleChild,
  mdiInbox,
  mdiKeyboardEsc,
  mdiKeyboardTab,
  mdiMagnify,
  mdiOpenInNew,
  mdiPenguin,
  mdiTag,
} from "@mdi/js"
import Icon from "@mdi/react"
import { Dialog, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import type { inferRouterOutputs } from "@trpc/server"
import { useDebounce } from "@uidotdev/usehooks"
import type { document_category } from "dcp-types"
import { useParams, useRouter } from "next/navigation"
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from "react"
import { isWindows, osName } from "react-device-detect"
import deParticipant from "../../[locale]/(participant)/_messages/de.json"
import useAside from "../Aside/hooks/useAside"
import NotFoundError from "../NotFoundError"
import de from "./messages/de.json"

const isLinux = osName.toLowerCase().includes("linux")

const registerGlobalKeyboardShortcutCmdPlusK = (
  callback: (e: KeyboardEvent) => void = () => {},
) => {
  window.removeEventListener("keydown", callback)
  window.addEventListener("keydown", callback)
}

type GlobalSearchResultItem =
  inferRouterOutputs<CaseRouter>["search"]["results"][number]

interface SearchItem {
  caseId: string | null
  objectId: string | null
  icon: string | null
  title: string | null
  subtitle: string[]
  type: GlobalSearchResultItem["type"]
  reasonLabel?: string | null
  reason: GlobalSearchResultItem["reason"] | null
}

const SEARCH_RESULT_LIMIT = 20

function getIconByType(
  type: SearchItem["type"],
  documentCategory?: document_category | null,
) {
  switch (type) {
    case "case":
      return mdiFolder
    case "task":
      return mdiCheckCircle
    case "document":
      return documentCategory !== undefined
        ? getCategoryIcon(documentCategory)
        : mdiInbox
    case "participant_case_reference":
      return mdiTag
    case "participant":
      return mdiHumanMaleFemaleChild
    case "vehicle":
      return mdiCar
  }

  assertUnreachable(type)
}

function getHighlightedText(text: string, highlight: string) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"))
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          // biome-ignore lint/suspicious/noArrayIndexKey: as we do not have a unique key, we use the index
          key={i}
          className={
            part.toLowerCase() === highlight.toLowerCase()
              ? "bg-yellow-300"
              : ""
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  )
}

export default function AppGlobalSearch() {
  const { t } = useTranslation(de)
  const { t: tParticipant } = useTranslation(deParticipant)
  const { createAsideSearch, asideSearch } = useAside()

  const queryClient = useQueryClient()

  const theme = useTheme()
  const { openDialog, closeDialog, dialogProps } = useDialog()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  const router = useRouter()
  const params = useParams()

  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, DebounceDelay.FastInputChange)
  const isSearchActive = debouncedSearch.trim().length > 0

  const userSessionQuery = useGetUserSessionData()

  const casesQuery = trpc.case.list.useQuery(
    {
      filterOptions: {
        assignee: userSessionQuery.data?.userId ?? "",
      },
      sortBy: [{ field: "updated_at", sort: "desc" }],
      limit: SEARCH_RESULT_LIMIT,
    },
    {
      enabled:
        !!userSessionQuery.data?.userId && !isSearchActive && dialogProps.open,
    },
  )
  const cases = useMemo(
    () => casesQuery.data?.results ?? [],
    [casesQuery.data?.results],
  )

  const globalSearchQuery = trpc.case.search.useQuery(
    {
      search: debouncedSearch,
      limit: SEARCH_RESULT_LIMIT,
    },
    {
      enabled: isSearchActive && dialogProps.open,
    },
  )

  const { searchResults, isSearchResultsFetching, searchResultsError } =
    useMemo(
      () => ({
        searchResults: globalSearchQuery.data?.results ?? [],
        isSearchResultsFetching: globalSearchQuery.isFetching,
        searchResultsError: globalSearchQuery.error,
      }),
      [globalSearchQuery],
    )

  const lastUsedCases: SearchItem[] = useMemo(
    () =>
      cases.map((item) => ({
        caseId: item.id,
        objectId: item.id,
        icon: mdiFolder,
        title: t("search.dialog.type.case.name"),
        subtitle: [item.file_number, item.name].filter(Boolean) as string[],
        type: "case",
        reasonLabel: null,
        reason: null,
      })),
    [t, cases],
  )

  const getName = useCallback(
    (item: GlobalSearchResultItem): string => {
      const type = item.type

      switch (type) {
        case "participant_case_reference":
        case "participant":
        case "case":
        case "task":
        case "document":
        case "vehicle":
          return t(`search.dialog.type.${type}.name`)
      }

      assertUnreachable(type)
    },
    [t],
  )

  const getReasonLabel = useCallback(
    (item: GlobalSearchResultItem): string => {
      const type = item.type

      const reason =
        type === "participant_case_reference"
          ? item.reference_type === "other"
            ? item.reference_custom_type || tParticipant("reference_type.other")
            : tParticipant(`reference_type.${item.reference_type ?? undefined}`)
          : t(`search.dialog.reason.${item.reason}`)

      const field =
        item.reason === "license_plate" ? item.field?.toUpperCase() : item.field

      return t("search.dialog.reasonLabel", {
        reason,
        value: field || "",
      })
    },
    [t, tParticipant],
  )

  const searchResultItems: SearchItem[] = useMemo(
    () =>
      (searchResults ?? []).map((item) => {
        const type = item.type
        return {
          caseId: item.case_id,
          objectId: item.object_id,
          icon:
            type === "document" && item.document_category
              ? getIconByType(type, item.document_category)
              : getIconByType(type),
          title: getName(item),
          subtitle: [item.file_number, item.case_name].filter(
            Boolean,
          ) as string[],
          reasonLabel: getReasonLabel(item),
          reason: item.reason,
          type: type,
        }
      }),
    [getName, getReasonLabel, searchResults],
  )

  const items: SearchItem[] = useMemo(
    () => (isSearchActive ? searchResultItems : lastUsedCases),
    [isSearchActive, lastUsedCases, searchResultItems],
  )

  const loading = useDebounce(
    casesQuery.isFetching || isSearchResultsFetching,
    DebounceDelay.LoadingState,
  )

  const combinationKeyIcon = useMemo(() => {
    if (isWindows) {
      return (
        <KeyboardShortcutCharacter className="font-bold text-sm -tracking-[1px]">
          {t("search.dialog.keyboardShortcut.ctrl")}
        </KeyboardShortcutCharacter>
      )
    }
    if (isLinux) {
      return <KeyboardShortcutIcon>{mdiPenguin}</KeyboardShortcutIcon>
    }
    return (
      <KeyboardShortcutIcon>{mdiAppleKeyboardCommand}</KeyboardShortcutIcon>
    )
  }, [t])

  const handleClose = useCallback(() => {
    closeDialog()
    setSearch("")
  }, [closeDialog])

  const handleStartSearch = useCallback(() => {
    openDialog()
  }, [openDialog])

  const handleGlobalKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const isWindowsCtrlPlusK = isWindows && e.ctrlKey && e.key === "k"
      const isUnixCmdPlusK = e.metaKey && e.key === "k"

      if (isWindowsCtrlPlusK || isUnixCmdPlusK) {
        e.stopImmediatePropagation()
        e.preventDefault()
        handleStartSearch()
      }
    },
    [handleStartSearch],
  )

  const openRoute = useCallback(
    (
      route: string | null,
      openInNew: boolean,
      asideProps?: Parameters<typeof createAsideSearch>,
    ) => {
      const fallbackPathname =
        typeof window !== "undefined"
          ? window.location.pathname
          : COMMON_USER_ROUTE.IN

      if (openInNew && typeof window !== "undefined") {
        const origin = window.location.origin
        const asideSearch = asideProps ? createAsideSearch(...asideProps) : ""

        window
          .open(
            asideSearch
              ? `${origin}${route ?? fallbackPathname}?${asideSearch}`
              : `${origin}${route ?? fallbackPathname}`,
            "_blank",
          )
          ?.focus()
      } else {
        const asideSearch = asideProps ? createAsideSearch(...asideProps) : ""
        router.push(
          asideSearch
            ? `${route ?? fallbackPathname}?${asideSearch}`
            : `${route ?? fallbackPathname}`,
        )
      }
    },
    [createAsideSearch, router],
  )

  const getCaseRoute = useCallback(
    (
      caseId: string,
      tab: "details" | "tasks" | "documents" | "relations" = "details",
    ) =>
      replaceSlugs(
        `${LAWFIRM_ONLY_ROUTE.IN}/[lawyerId]/cases/[caseId]/${tab}`,
        {
          ...params,
          caseId,
        },
        // search is not needed, as it is added in "openRoute" method
      ),
    [params],
  )

  const handleItemSelect = useCallback(
    (item: SearchItem, openInNew = false) => {
      const type = item.type

      switch (type) {
        case "case": {
          const route = item.caseId
            ? getCaseRoute(item.caseId, "details")
            : null

          openRoute(route, openInNew)
          handleClose()
          return
        }
        case "document": {
          const asideProps: Parameters<typeof createAsideSearch> | undefined =
            item.objectId ? ["document", item.objectId, "documents"] : undefined

          const route = item.caseId
            ? getCaseRoute(item.caseId, "documents")
            : null

          openRoute(route, openInNew, asideProps)
          handleClose()
          return
        }
        case "task": {
          const asideProps: Parameters<typeof createAsideSearch> | undefined =
            item.objectId ? ["task", item.objectId, "tasks"] : undefined

          const route = item.caseId ? getCaseRoute(item.caseId, "tasks") : null

          openRoute(route, openInNew, asideProps)
          handleClose()
          return
        }
        case "participant":
        case "participant_case_reference":
        case "vehicle": {
          const route = item.caseId
            ? getCaseRoute(item.caseId, "relations")
            : null

          openRoute(route, openInNew)
          handleClose()
          return
        }
      }

      assertUnreachable(type)
    },
    [handleClose, getCaseRoute, openRoute],
  )

  const handleSearch = useCallback(
    (value: string) => {
      const searchString = value.trim()
      queryClient.cancelQueries({ queryKey: getQueryKey(trpc.case.search) })
      setSearch(searchString)
    },
    [queryClient],
  )

  useEffect(() => {
    registerGlobalKeyboardShortcutCmdPlusK(handleGlobalKeyDown)
  }, [handleGlobalKeyDown])

  useEffect(() => {
    if (dialogProps.open) {
      setSearch("")
    }
  }, [dialogProps.open])

  if (userSessionQuery.error || casesQuery.error || searchResultsError) {
    throw userSessionQuery.error ?? casesQuery.error ?? searchResultsError
  }

  return (
    <>
      <Stack
        className="bg-white py-1.5 px-3 rounded cursor-pointer"
        direction="row"
        alignItems="center"
        onClick={handleStartSearch}
        spacing={1}
      >
        <Icon path={mdiMagnify} size={1} color={theme.palette.action.active} />
        <Typography className="whitespace-nowrap" color="text.disabled">
          {t("search.placeholder")}
        </Typography>
        <Box flexGrow={1} />
        <KeyboardShortcut>
          {combinationKeyIcon}
          <KeyboardShortcutCharacter>K</KeyboardShortcutCharacter>
        </KeyboardShortcut>
      </Stack>

      <Dialog
        {...dialogProps}
        fullScreen={fullScreen}
        onClose={handleClose}
        scroll="paper"
        maxWidth="sm"
        classes={{
          paper: "absolute top-0",
        }}
      >
        <DialogTitle>
          <TextField
            data-testid="global-search-dialog-input"
            autoFocus
            fullWidth
            size="medium"
            variant="outlined"
            placeholder={t("search.dialog.placeholder")}
            InputProps={{
              startAdornment: (
                <Icon
                  className="pr-2 flex-shrink-0"
                  path={mdiMagnify}
                  size={1.5}
                  color={theme.palette.action.active}
                />
              ),
            }}
            onChange={(e) => {
              handleSearch(e.target.value)
            }}
          />
        </DialogTitle>
        <DialogContent className="py-0">
          <Collapse in={!loading && items.length === 0}>
            <NotFoundError
              customTitle={t("search.dialog.noResults.title")}
              customMessage={t("search.dialog.noResults.message")}
            />
          </Collapse>
          <Collapse in={!loading && items.length > 0}>
            <List
              className="w-full bg-white"
              component="nav"
              dense
              subheader={
                <ListSubheader className="px-0" component="div" disableSticky>
                  {isSearchActive
                    ? t("search.dialog.searchResults")
                    : t("search.dialog.lastEditedCases")}
                </ListSubheader>
              }
            >
              {items.map((item) => (
                <ListItemButton
                  key={`${item.caseId ?? "X"}-${item.objectId ?? "X"}`}
                  data-testid={"search-result-item"}
                  className="rounded border-1 border-solid border-stone-200 mb-2"
                  dense
                  onClick={handleItemSelect.bind(null, item, false)}
                >
                  {item.icon && (
                    <ListItemIcon>
                      <Icon path={item.icon} size={1} />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    data-testid={item.title}
                    primary={item.title}
                    primaryTypographyProps={{
                      className: "overflow-hidden text-ellipsis pr-1",
                    }}
                    secondary={
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        divider={<Divider flexItem orientation="vertical" />}
                      >
                        {item.subtitle.map((subtitle) => (
                          <Typography
                            key={subtitle}
                            variant="body2"
                            color="text.secondary"
                          >
                            {subtitle}
                          </Typography>
                        ))}
                      </Stack>
                    }
                    secondaryTypographyProps={{
                      component: "div",
                    }}
                  />
                  <ListItemIcon className="min-w-0 items-center">
                    {item.reason && (
                      <Tooltip title={item.reason}>
                        <div role="contentinfo">
                          <Chip
                            data-testid={"search-result-item-reason"}
                            className="max-w-[200px]"
                            size="small"
                            label={getHighlightedText(
                              item.reasonLabel ?? "",
                              search.trim(),
                            )}
                          />
                        </div>
                      </Tooltip>
                    )}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleItemSelect(item, true)
                      }}
                    >
                      <Icon path={mdiOpenInNew} size={1} />
                    </IconButton>
                  </ListItemIcon>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Collapse in={loading}>
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          </Collapse>
        </DialogContent>
        <DialogActions className="justify-start items-center gap-1 px-6 py-4">
          <KeyboardShortcut>
            <KeyboardShortcutIcon>{mdiArrowLeftBottom}</KeyboardShortcutIcon>
          </KeyboardShortcut>
          <Typography color="text.secondary" variant="caption" mr={1}>
            {t("search.dialog.keyboardShortcut.enter")}
          </Typography>
          <KeyboardShortcut>
            <KeyboardShortcutIcon>{mdiKeyboardTab}</KeyboardShortcutIcon>
          </KeyboardShortcut>
          <Typography color="text.secondary" variant="caption" mr={1}>
            {t("search.dialog.keyboardShortcut.upAndDown")}
          </Typography>
          <KeyboardShortcut>
            <KeyboardShortcutIcon>{mdiKeyboardEsc}</KeyboardShortcutIcon>
          </KeyboardShortcut>
          <Typography color="text.secondary" variant="caption" mr={1}>
            {t("search.dialog.keyboardShortcut.esc")}
          </Typography>
        </DialogActions>
      </Dialog>
    </>
  )
}

const KeyboardShortcut = ({ children }: { children?: ReactNode }) => {
  return (
    <Typography
      className="bg-stone-100 px-1 py-0 rounded flex flex-row items-center h-6 m-0 gap-1"
      color="text.secondary"
      component="span"
    >
      {children}
    </Typography>
  )
}

const KeyboardShortcutIcon = ({ children }: { children?: string }) => {
  if (!children) return null
  return (
    <kbd className="leading-[0]">
      <Icon path={children} size={0.66 /* 16 px */} />
    </kbd>
  )
}

const KeyboardShortcutCharacter = ({
  className = "",
  children,
}: { className?: string; children?: ReactNode }) => {
  return <kbd className={`leading-1 ${className}`}>{children}</kbd>
}
