"use client"

import { darken, styled } from "@mui/material/styles"

import { Container as MuiContainer } from "@/components/ui/layout/Container"
import type { ContainerProps } from "@mui/material"
import { ListItem as MuiListItem } from "@/components/ui/data-display/ListItem"
import type { ListItemProps } from "@mui/material"

export const Container = styled(MuiContainer)<
  ContainerProps & { open?: boolean }
>(({ theme }) => ({
  ["&.MuiContainer-root"]: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  },
  width: theme.spacing(30),
  height: theme.spacing(4),
  transition: theme.transitions.create(["padding"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

export const ListItem = styled(MuiListItem)<
  ListItemProps & { selected?: boolean }
>(({ theme, selected }) => ({
  padding: 0,
  paddingTop: theme.spacing(0.5),
  alignItems: "center",
  height: theme.spacing(4.5),
  borderRadius: theme.spacing(0.5),
  backgroundColor: selected ? theme.palette.action.selected : undefined,
  "&:hover": {
    backgroundColor: selected
      ? darken(theme.palette.action.selected, 0.1)
      : theme.palette.action.hover,
  },
  transition: theme.transitions.create(["backgroundColor"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard,
  }),
}))
