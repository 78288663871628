import { Box } from "@/components/ui/layout/Box"
import { IconButton } from "@/components/ui/inputs/IconButton"
import Icon from "@mdi/react"
import { useTheme } from "@mui/material/styles"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import React, { useEffect, useState } from "react"
import { LOGO_SIZE_FACTOR, LogoIcon, LogoText } from "../Logo"
import { Stack } from "@/components/ui/layout/Stack"

export const testPrefix = "DrawerToggle"
export interface DrawerToggleProps {
  open?: boolean
  disabled?: boolean
  onChange?: () => void
}
export default function DrawerToggle({
  open = true,
  onChange = () => {},
  disabled = false,
}: DrawerToggleProps) {
  const theme = useTheme()

  const [useOpen, setUseOpen] = useState(open)
  const [useDisabled, setUseDisabled] = useState(disabled)

  useEffect(() => setUseOpen(open), [open])
  useEffect(() => setUseDisabled(disabled), [disabled])

  return (
    <React.Fragment>
      <Stack spacing={theme.spacing(1)} direction="row" alignItems="center">
        <LogoIcon
          height={
            open ? theme.spacing(2.5 * LOGO_SIZE_FACTOR) : theme.spacing(2.5)
          }
        />
        <LogoText height={open ? theme.spacing(2.5) : theme.spacing(0)} />
      </Stack>
      {open && <Box flexGrow={1} />}
      <IconButton disabled={useDisabled} onClick={onChange} sx={{ padding: 0 }}>
        <Icon
          size={theme.spacing(3)}
          color={theme.palette.action.active}
          path={useOpen ? mdiChevronLeft : mdiChevronRight}
        />
      </IconButton>
    </React.Fragment>
  )
}
