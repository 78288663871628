import useForceRerender from "./useForceRerender"
import { type RefObject, useMemo, useRef, type LegacyRef } from "react"

type BoundingRectResult<T extends HTMLElement> = [
  RefObject<T> | LegacyRef<T>,
  DOMRect,
  {
    reevaluate: () => void
  },
]

export default function useBoundingRect<T extends HTMLElement>(
  options: { reevaluateAfterMs?: number | false } = {},
): BoundingRectResult<T> {
  const reevaluate = useForceRerender(true, {
    delay: options.reevaluateAfterMs || 0,
    disable: !options.reevaluateAfterMs,
  })
  const ref = useRef<T>(null)

  const boundingRect: DOMRect = ref.current?.getBoundingClientRect() ?? {
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    toJSON: () => null,
  }

  const result: BoundingRectResult<T> = useMemo(
    () => [
      ref,
      boundingRect,
      {
        reevaluate,
      },
    ],
    [boundingRect, reevaluate],
  )

  return result
}
