import { Divider } from "@/components/ui/data-display/Divider"
import { Skeleton } from "@/components/ui/feedback/Skeleton"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import type { StackProps } from "@mui/material"

interface LoadingSkeletonLayoutProps extends StackProps {}

export function LoadingHeaderContent(props: LoadingSkeletonLayoutProps) {
  return (
    <Stack py={2} width="100%" spacing={1} {...props}>
      <Skeleton height={32} />
      <Stack width="66%">
        <Skeleton />
      </Stack>
    </Stack>
  )
}

export function LoadingBaseContent(props: LoadingSkeletonLayoutProps) {
  return (
    <Box width="100%">
      <Stack py={2} width="66%" spacing={1} {...props}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    </Box>
  )
}

export function LoadingFilterBar(props: LoadingSkeletonLayoutProps) {
  return (
    <Stack py={2} width="100%" spacing={1} {...props}>
      <Skeleton height={24} />
    </Stack>
  )
}

export function LoadingTable(props: LoadingSkeletonLayoutProps) {
  const rows = Array(5)
    .fill({})
    .map((_, i) => i)
  const columns = Array(5)
    .fill({})
    .map((_, i) => i)

  return (
    <Stack py={2} width="100%" spacing={1} {...props}>
      <Stack direction="row" spacing={1}>
        {columns.map((colIdx, i) => (
          <Stack
            key={`loading-table-header-cell-${colIdx}`}
            direction="row"
            spacing={1}
            width="100%"
          >
            <Skeleton width="100%" />
            {i <= columns?.length - 2 && (
              <Divider orientation="vertical" flexItem />
            )}
          </Stack>
        ))}
      </Stack>
      <Divider />
      {rows.map((rowIdx, i) => (
        <Stack key={`loading-table-row-${rowIdx}`} spacing={0.5}>
          <Stack direction="row" spacing={2}>
            {columns.map((colIdx, j) => (
              <Skeleton
                key={`loading-table-row-${rowIdx}-cell-${colIdx}`}
                width="100%"
              />
            ))}
          </Stack>
          <Divider />
        </Stack>
      ))}
      <Stack direction="row" justifyContent="right" spacing={2}>
        <Skeleton width="33%" />
      </Stack>
    </Stack>
  )
}
