"use client"

import { useTheme } from "@mui/material/styles"
import { type ReactNode, useState } from "react"

import Icon from "@mdi/react"

import DrawerListItemGroup from "@/app/_components/DrawerListItemGroup"
import { ListItemButton } from "@/components/ui/data-display/ListItemButton"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Collapse } from "@/components/ui/utils/Collapse"
import { mdiChevronDown } from "@mdi/js"

export interface DrawerNavGroupProps {
  children?: ReactNode
  drawerOpen?: boolean
  groupId?: string
  collapseTitle?: string
  collapsable?: boolean
}
export default function DrawerNavGroup({
  children,
  groupId = "nav",
  drawerOpen,
  collapseTitle,
  collapsable = false,
}: DrawerNavGroupProps) {
  const theme = useTheme()

  const [collapsed, setCollapsed] = useState(false)

  return (
    <DrawerListItemGroup
      groupId={groupId}
      data-testid={`DrawerNavGroup-${groupId}`}
    >
      {collapsable && (
        <ListItemButton
          onClick={() => setCollapsed(!collapsed)}
          sx={{ height: theme.spacing(4.5) }}
          data-testid={`DrawerListItem-toggle-DrawerNavGroup-${groupId}`}
          className="items-center"
        >
          {drawerOpen && (
            <ListItemText sx={{ color: theme.palette.text.secondary }}>
              {collapseTitle}
            </ListItemText>
          )}
          <Icon
            size={1}
            className={`transition-all ${
              collapsed ? "rotate-0" : "rotate-180"
            }`}
            color={theme.palette.text.disabled}
            path={mdiChevronDown}
          />
        </ListItemButton>
      )}
      <Collapse in={!collapsed}>{children}</Collapse>
    </DrawerListItemGroup>
  )
}
