"use client"

import { Typography } from "@/components/ui/data-display/Typography"
import { Stack } from "@/components/ui/layout/Stack"
import Icon from "@mdi/react"
import { useTheme } from "@mui/material/styles"
import type { Route } from "next"
import Link from "next/link"

export interface BreadcrumbItemProps {
  href?: Route
  active?: boolean
  icon?: string
  iconRotation?: number
  text?: string
}

export default function BradcrumbItem({
  href,
  active,
  icon,
  iconRotation,
  text,
}: BreadcrumbItemProps) {
  const theme = useTheme()
  const color = active
    ? theme.palette.text.primary
    : theme.palette.text.secondary

  return (
    <Stack direction="row" alignItems="center" spacing={theme.spacing(1)}>
      {icon && (
        <Icon
          color={color}
          size={theme.spacing(2)}
          path={icon}
          style={{
            transform: iconRotation ? `rotate(${iconRotation}deg)` : "",
          }}
        />
      )}
      {text && href && (
        <Link href={href} className="no-underline hover:underline">
          <Typography
            variant="body1"
            sx={{
              color,
            }}
          >
            {text}
          </Typography>
        </Link>
      )}
      {text && !href && (
        <Typography
          variant="body1"
          sx={{
            color,
          }}
        >
          {text}
        </Typography>
      )}
    </Stack>
  )
}
