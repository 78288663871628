"use client"

import type { FC } from "react"
import {
  Breadcrumbs as MuiBreadcrumbs,
  type BreadcrumbsProps,
} from "@mui/material"

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => (
  <MuiBreadcrumbs {...props} />
)
