import { useState, useCallback, useEffect } from "react"

export default function useForceRerender(
  // we have to only allow primitive values as dependencies,
  // otherwise the force update will trigger on every uncont
  value: number | boolean | string | null | undefined,
  options?: { delay?: number; disable?: boolean },
) {
  const [, updateState] = useState<object | null>(null)
  const forceUpdate = useCallback(() => updateState({}), [])

  const delay = options?.delay
  const disable = options?.disable

  // biome-ignore lint/correctness/useExhaustiveDependencies: we keep "value" as dependency as the value change is the trigger for force update
  useEffect(() => {
    if (disable === true) {
      return
    }

    if (delay) {
      setTimeout(() => {
        forceUpdate()
      }, delay ?? 0)
    } else {
      forceUpdate()
    }
    /**
     * attention: we keep "value" as dependency as the value change is the trigger for force update,
     * this useEffect should only depend on memoized values or primitive values,
     * otherwise it will trigger a force update on every uncontrollable change
     */
  }, [value, delay, disable, forceUpdate])

  return forceUpdate
}
