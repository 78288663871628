import { Stack } from "@/components/ui/layout/Stack"
import {
  LoadingBaseContent,
  LoadingHeaderContent,
} from "../LoadingSkeletonLayout"

export default function AsideLoading() {
  return (
    <Stack spacing={2} p={1.5}>
      <LoadingHeaderContent p={0} spacing={0.5} />
      <LoadingBaseContent p={0} spacing={0.5} />
    </Stack>
  )
}
