"use client"

import { trpc } from "@/app/_trpc/client"
import useWindowFocus from "@/hooks/useWindowFocus"
import { useGetUserSessionData } from "@/utils/lib"
import { checkUUID } from "@/utils/validation/validators"
import { useQueryClient, type QueryKey } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { useCallback } from "react"

export default function UserEventSubscription() {
  const userSessionDataQuery = useGetUserSessionData()
  const windowFocused = useWindowFocus()

  const userId = userSessionDataQuery.data?.id

  const queryClient = useQueryClient()
  const invalidateQueries = useCallback(
    (queryKeys: QueryKey[]) => {
      for (const queryKey of queryKeys) {
        queryClient.invalidateQueries({ queryKey })
      }
    },
    [queryClient],
  )

  trpc.auth.streamEvents.useSubscription(
    {
      type: "notification",
      // @ts-expect-error fine, as we enable the subscription only when the ID is valid
      entityId: userId,
    },
    {
      enabled: windowFocused && checkUUID(userId),
      onData(response) {
        const { data } = response
        const { type } = data

        if (type === "notification") {
          invalidateQueries([
            getQueryKey(trpc.notification.check),
            getQueryKey(trpc.notification.list),
          ])
        }
      },
      onStarted() {
        console.info(`Start subscribing "notification/${userId}"`)
      },
      onError(err) {
        console.error(`Error subscribing to "notification/${userId}":`, err)
      },
    },
  )

  return null
}
