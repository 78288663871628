import { usePathname } from "next/navigation"
import { useState, useCallback, useEffect } from "react"

export default function useOptimisticPathname() {
  const urlPathname = usePathname()

  const [pathname, setPathname] = useState(urlPathname)

  const updatePathname = useCallback((href: string) => {
    setPathname(href)
  }, [])

  useEffect(() => {
    setPathname(urlPathname)
  }, [urlPathname])

  return { pathname, updatePathname }
}
